import React, { useState, useEffect } from 'react';
import {
  Clock,
  MapPin,
  Phone,
  ChevronRight,
  Star,
  Utensils,
  X,
  Menu,
  Share2
} from 'lucide-react';

const menuCategories = {
  kazdal: {
    name: "Nos Spécialités",
    icon: "🫔",
    items: [
      { name: "Kazdal Poulet Avocat", price: "9.90€", description: "Msemen garni de poulet mariné maison, Avocat, Oignons frits, Oignons caramélisés et sauces maison. Servi avec frites et boisson 33cl", popular: true },
      { name: "Kazdal Kefta Cheddar", price: "9.90€", description: "Msemen garni de kefta marinée maison, Cheddar, Concombre, Oignons frits, Oignons caramélisés et sauce maison. Servi avec frites et boisson 33cl", popular: true },
      { name: "Kazdal Thon Harssa", price: "9.90€", description: "Msmemen garni de Thon, Oeuf, Tomates, Olives noires, et Sauce harissa. Servi avec frites et boisson 33cl" },
      { name: "Kazdal Feta", price: "9.90€", description: "Msemen garni de feta, Méchouia, Concombre et Olives noires avec une sauce maison. Servi avec frites et boisson 33cl", popular: true }
    ]
  },
  sandwiches: {
    name: "Sandwiches Marocains",
    icon: "🥙",
    items: [
      { name: "Batbout Poulet", price: "8.90€", description: "Poulet mariné, légumes caramélisés, salade et fromage, servi avec frites" },
      { name: "Batbout Kefta", price: "8.90€", description: "Viande hachée marinée aux épices orientales, salade, oignons caramélisés et fromage" },
      { name: "Batbout Poulet Olives", price: "9.90€", description: "Batbout garni de poulet et olives façon tajine" }
    ]
  },
  tajines: {
    name: "Tajines",
    icon: "🥘",
    items: [
      { name: "Tajine Kefta aux Oeufs", price: "10.90€"},
      { name: "Tajine Poulet aux Olives et Frites", price: "12.90€"},
      { name: "Tajine de Veau, Pruneaux et amandes grillées", price: "14.90€"}
    ]
  },
  entrees: {
    name: "Entrées",
    icon: "🥗",
    items: [
      { name: "Formule découverte", price: "11.90€", description: "Assortiment de 3 entrées" },
      { name: "Salade Marocaine", price: "3.90€", description: "Tomates, oignons, poivrons, persil, huile d'olive" },
      { name: "Salade Zaalouk Caviar", price: "4.90€", description: "Zaalouk, caviar d'aubergines, Tomates, Persil, Ail et Huile d'Olive" },
      { name: "Salade de carotte", price: "4.50€", description: "Carotte, Ail, Coriandre, Persil et Huile d'Olive" },
      { name: "Salade de Pomme de Terre", price: "4.50€", description: "Pomme de Terre, Persil et Huile d'Olive" },
      { name: "Soupe Harira", price: "4.50€", description: "Soupe traditionelle Marocaine" },
      { name: "Salade de Betterave", price: "3.90", description: "Salade de Betterave" },
      { name: "Brick Viande Hachée", price: "2.50" },
      { name: "Brick Thon", price: "2.50" },
      { name: "Brick Epinard Fromage", price: "2.50" },
      { name: "Trio de Brick", price: "6.50" }
    ]
  },
  plats: {
    name: "Plats Traditionnels",
    icon: "🍛",
    items: [
      { name: "Tanjia de veau", price: "17.90€", description: "Viande de veau fondante mijotée avec des épices, du citron confit au . Cuite lentement pour un gout intense." },
      { name: "Rfissa/Trid", price: "14.90€", description: "Plat ancestral à base de Msemen, Poulet, Lentilles avec ses épices Marocaines" }
    ]
  },
  specialites: {
    name: "Couscous",
    icon: "🍲",
    items: [
      { name: "Couscous Végétarien", price: "9.90€", description:"Semoule fine de couscous, légumes variés (carottes, courgettes, navets, potiron), servi avec un bouillon parfumé aux épices."},
      { name: "Couscous Poulet", price: "13.90€", description: "Semoule fine de couscous, poulet, légumes variés (carottes, courgettes, navets, potiron, pois chiches), servi avec un bouillon parfumé aux épices"},
      { name: "Couscous Merguez", price: "14.90€", description: "Semoule fine de couscous, merguez, légumes variés (carottes, courgettes, navets, potiron, pois chiches), servi avec un bouillon parfumé aux épices."},
      { name: "Couscous Viande de Veau", price: "14.90", description: "Semoule fine de couscous, viande de veau, légumes variés (carottes, courgettes, navets, potiron, pois chiches), servi avec un bouillon parfumé aux épices." },
      { name: "Couscous Royal", price: "17.90", description: "Semoule fine de couscous, merguez, viande de veau, poulet, légumes variés (carottes, courgettes, navets, potiron, pois chiches), servi avec un bouillon parfumé aux épices." }
    ]
  },
  dessert: {
    name: "Desserts",
    icon: "🍮",
    items: [
      { name: "Dessert du jour", price: "3.90€" },
      { name: "3 Gâteaux marocains avec un thé", price: "3.90€" }
    ]
  },
};

const BackgroundSection = ({ children, image, overlay = true, className = "", id = "" }) => (
    <section id={id} className={`relative ${className}`}>
      <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url("${image}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
      />
      {overlay && <div className="absolute inset-0 bg-black/40 z-10" />}
      <div className="relative z-20">
        {children}
      </div>
    </section>
);

const Navigation = ({ isOpen, toggleMenu }) => {
  const handleClick = (e, target) => {
    e.preventDefault();
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      toggleMenu();
    }
  };

  return (
      <nav className={`fixed top-0 right-0 h-full w-64 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} z-50`}>
        <button onClick={toggleMenu} className="absolute top-4 right-4 p-2">
          <X className="h-6 w-6" />
        </button>
        <div className="p-8 pt-16">
          <div className="space-y-6">
            {[
              { name: 'Accueil', id: 'accueil' },
              { name: 'Menu', id: 'menu' },
              { name: 'À propos', id: 'a-propos' },
              { name: 'Contact', id: 'contact' }
            ].map((item) => (
                <a
                    key={item.name}
                    href={`#${item.id}`}
                    onClick={(e) => handleClick(e, item.id)}
                    className="block text-lg hover:text-amber-600 transition-colors cursor-pointer"
                >
                  {item.name}
                </a>
            ))}
          </div>
        </div>
      </nav>
  );
};

const MenuCategory = ({ category, items, icon }) => (
    <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-lg overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-semibold text-amber-800">{category}</h3>
          <span className="text-2xl">{icon}</span>
        </div>
        <div className="space-y-4">
          {items.map((item, index) => (
              <div key={index} className="border-b border-gray-100 last:border-0 pb-4 last:pb-0">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium flex items-center">
                      {item.name}
                      {item.popular && (
                          <span className="ml-2 bg-amber-100 text-amber-800 text-xs px-2 py-1 rounded-full">
                                            Populaire
                                        </span>
                      )}
                    </h4>
                    <p className="text-sm text-gray-600 mt-1">{item.description}</p>
                  </div>
                  <span className="text-amber-600 font-semibold">{item.price}</span>
                </div>
              </div>
          ))}
        </div>
      </div>
    </div>
);

const RestaurantWebsite = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('specialites');

  const backgroundImages = {
    hero: "/images/fond_darna_web.jpg",
    menu: "/images/fond_darna_web.jpg",
    features: "/images/fond_darna_web.jpg"
  };


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
      <div className="min-h-screen">
        {/* Header */}
        <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-md shadow-sm z-40">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center h-16">
              <a
                  href="#accueil"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('accueil');
                  }}
                  className="text-2xl font-bold text-amber-800"
              >
                <img
                    src="/images/favicon.ico"
                    alt="DARNA"
                    className="h-12 w-auto"
                />
              </a>
              <button onClick={() => setIsMenuOpen(true)} className="p-2">
                <Menu className="h-6 w-6"/>
              </button>
            </div>
          </div>
        </header>

        <Navigation isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(false)}/>

        {/* Hero Section */}
        <BackgroundSection
            id="accueil"
            image={backgroundImages.hero}
            className="min-h-screen pt-16"
        >
          <div className="container mx-auto px-4 py-32">
            <div className="max-w-2xl mx-auto text-center">
              <div className="bg-white/90 backdrop-blur-sm p-8 rounded-lg">
                <h1 className="text-5xl font-bold mb-6 text-amber-800">Découvrez l'Authenticité Marocaine</h1>
                <p className="text-xl mb-8 text-amber-700">Une expérience culinaire unique au cœur de Mantes la
                  Jolie</p>
                <div className="flex gap-4 justify-center">
                  <a
                      href="#menu"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection('menu');
                      }}
                      className="bg-amber-600 text-white px-8 py-3 rounded-lg hover:bg-amber-700 transition"
                  >
                    Notre Menu
                  </a>
                  <a href="tel:0130937189"
                     className="border border-amber-600 text-amber-600 px-8 py-3 rounded-lg hover:bg-amber-600 hover:text-white transition">
                    Réserver
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Info Cards */}
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white/90 backdrop-blur-sm rounded-lg p-6 shadow-lg">
                <Clock className="h-8 w-8 text-amber-600 mb-4"/>
                <h3 className="font-semibold mb-2">Horaires</h3>
                <p className="text-gray-600">11h30-14h30 / 19h00-22h30</p>
                <p className="text-gray-600">Ven-Sam: jusqu'à 23h00</p>
              </div>
              <div className="bg-white/90 backdrop-blur-sm rounded-lg p-6 shadow-lg">
                <MapPin className="h-8 w-8 text-amber-600 mb-4"/>
                <h3 className="font-semibold mb-2">Adresse</h3>
                <p className="text-gray-600">24 Boulevard du Maréchal Juin</p>
                <p className="text-gray-600">Mantes La Jolie 78200</p>
              </div>
              <div className="bg-white/90 backdrop-blur-sm rounded-lg p-6 shadow-lg">
                <Phone className="h-8 w-8 text-amber-600 mb-4"/>
                <h3 className="font-semibold mb-2">Réservation</h3>
                <p className="text-gray-600">01.30.93.71.89</p>
                <p className="text-gray-600">Ouvert 7/7</p>
              </div>
            </div>
          </div>
        </BackgroundSection>

        {/* Menu Section avec filtres */}
        <BackgroundSection
            id="menu"
            image={backgroundImages.menu}
            className="py-28"
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12 text-white">Notre Menu</h2>

            {/* Menu Categories Buttons */}
            <div className="flex overflow-x-auto pb-4 mb-8 -mx-4 px-4">
              <div className="flex space-x-4 mx-auto">
                {Object.entries(menuCategories).map(([key, {name, icon}]) => (
                    <button
                        key={key}
                        onClick={() => handleCategoryChange(key)}
                        className={`flex items-center px-6 py-3 rounded-full whitespace-nowrap transition-colors ${
                            activeCategory === key
                                ? 'bg-amber-800 text-white'
                                : 'bg-white text-amber-800 hover:bg-amber-100'
                        }`}
                    >
                      <span className="mr-2">{icon}</span>
                      {name}
                    </button>
                ))}
              </div>
            </div>

            {/* Menu Items - Filtered */}
            <div className="md:max-w-2xl mx-auto">
              <MenuCategory
                  category={menuCategories[activeCategory].name}
                  items={menuCategories[activeCategory].items}
                  icon={menuCategories[activeCategory].icon}
              />
            </div>
          </div>
        </BackgroundSection>

        {/* Features Section */}
        <BackgroundSection
            id="a-propos"
            image={backgroundImages.features}
            className="py-20"
        >
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white/90 backdrop-blur-sm p-8 rounded-lg text-center">
                <Utensils className="h-12 w-12 mx-auto text-amber-600 mb-4"/>
                <h3 className="text-xl font-semibold mb-2">Cuisine Authentique</h3>
                <p className="text-gray-600">Des recettes traditionnelles transmises de génération en génération</p>
              </div>
              <div className="bg-white/90 backdrop-blur-sm p-8 rounded-lg text-center">
                <Star className="h-12 w-12 mx-auto text-amber-600 mb-4"/>
                <h3 className="text-xl font-semibold mb-2">Qualité Halal</h3>
                <p className="text-gray-600">Des ingrédients soigneusement sélectionnés et certifiés</p>
              </div>
              <div className="bg-white/90 backdrop-blur-sm p-8 rounded-lg text-center">
                <ChevronRight className="h-12 w-12 mx-auto text-amber-600 mb-4"/>
                <h3 className="text-xl font-semibold mb-2">Livraison</h3>
                <p className="text-gray-600">Profitez de nos plats directement chez vous</p>
              </div>
            </div>
          </div>
        </BackgroundSection>

        {/* Footer */}
        <footer id="contact" className="bg-amber-800 text-white py-12">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-3 gap-8">
              <div>
                <h4 className="text-2xl font-bold mb-4">DARNA</h4>
                <p className="text-amber-100">Restaurant marocain authentique</p>
              </div>
              <div>
                <h5 className="font-semibold mb-4">Contact</h5>
                <p>01.30.93.71.89</p>
                <p>24 Boulevard du Maréchal Juin</p>
                <p>Mantes La Jolie 78200</p>
              </div>
              <div>
                <h5 className="font-semibold mb-4">Suivez-nous</h5>
                <div className="flex space-x-4">
                  <a href="#" className="hover:text-amber-300 transition">
                    <Share2 className="h-6 w-6"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="border-t border-amber-700 mt-8 pt-8 text-center">
              <p className="text-amber-200">© 2024 DARNA - Tous droits réservés</p>
            </div>
          </div>
        </footer>
      </div>
  );
};

export default RestaurantWebsite;
